import React, { useEffect } from 'react'
import { PageContainer } from 'components/Containers/Page'
import { Box, Text, useTheme, Pressable } from 'native-base'
import ConfettiCannon from 'react-native-confetti-cannon'
import { usePurchases } from 'hooks/usePurchases'
import Lottie from 'lottie-react'
import CheckmarkAnimation from './checkmark-animation.json'
function AccountSubscriptionSuccess() {
  const {
    dispatch: { createReceipt },
    state: { initialized },
  } = usePurchases()

  window.dataLayer = window.dataLayer || [];

  useEffect(() => {
    if (initialized) {
      createReceipt()
      const savedSubscription = JSON.parse(window.localStorage.getItem('activeSubscription'));

      const orderReference = savedSubscription?.orderReference;
      const totalOrderValue = savedSubscription?.product?.priceString;

      if (orderReference && totalOrderValue) {
        dataLayer.push({
          'event': "PurchaseSuccess",
          'OrderReference': orderReference,
          'TotalOrderValue': totalOrderValue
        });
        console.log("==+===orderRef===+==", orderReference);
        console.log("OrderReference and TotalOrderValue events pushed");
      } else {
        if (!orderReference) {
          console.log("UNDEFINED OR");
        }
        if (!totalOrderValue) {
          console.log("UNDEFINED TOV");
        }
      }
    }
  }, [initialized])

  return (
    <>
      <PageContainer scheme="willowbrook" standAlone={false}>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
          width={'100%'}
        >
          <Lottie
            style={{ height: 400 }}
            animationData={CheckmarkAnimation}
            loop={false}
          />
          <Text variant="text-SuccessSub">
            You Have Successfully Subscribed!
          </Text>
        </Box>
      </PageContainer>
    </>
  )
}

export default AccountSubscriptionSuccess
