import { object, string } from 'yup'
import { INPUT_TYPES } from 'state/consts'
import { Button } from 'native-base'
import { RadioInput } from 'components/Form/Inputs/RadioInput'

import { TextAreaInput } from '../../../components/Form/Inputs/TextAreaInput'

export const activeReachConfig = {
  enableReinitialize: true,
  validationSchema: object().shape({
    reach: string().when('interval', (interval, schema) => {
      return interval !== null
        ? schema.required('Required')
        : schema.nullable().notRequired()
    }),
    interval: string().required('Required') | null,
  }),
  heading: { noSpacer: true },
  authFooter: [],
  rows: [
    {
      id: 'activeReach_row0',
      fields: [
        {
          id: 'reach',
          label: "Today's Active Reach",
          type: INPUT_TYPES.TEXT,
          component: TextAreaInput,
        },
      ],
    },
    {
      id: 'activeReach_row1',
      fields: [
        {
          id: 'interval',
          label: 'How often do you want to be reminded?',
          type: INPUT_TYPES.RADIO,
          component: RadioInput,
          items: [
            { id: 0, label: 'EVERY HOUR', value: 1 },
            { id: 1, label: 'EVERY 2 HOURS', value: 2 },
            { id: 2, label: 'EVERY 3 HOURS', value: 3 },
            { id: 3, label: 'SNOOZE', value: null },
          ],
        },
      ],
    },
  ],
  actionFooter: [],
  actions: {
    submit: {
      id: 'activeReach_submit',
      component: Button,
      variant: 'primary',
      variantDisabled: 'primaryDisabled',
      label: 'Set RemindersX',
    },
  },
}
