// Import necessary modules
import { Platform, Alert } from 'react-native';
import { useEffect, useMemo } from 'react';
import { routeNames } from '../../state/routeNames';
import { FIREBASE_COLLECTIONS } from '../consts';
import notifee, { EventType } from '@notifee/react-native';
import { firebase, firestore } from '../../config/firebase.web.js';

// Check if Firebase Messaging is supported before using it
const isMessagingSupported = () => {
  return Platform.OS !== 'web' || firebase.messaging.isSupported();
};

export async function saveTokenToDatabase(token) {
  if(!user || !user.uid) {
     return
  }

  const userId = user.uid

  // Add the token to the users datastore
  await firestore()
    .collection(FIREBASE_COLLECTIONS.USERS)
    .doc(userId)
    .set(
      {
        uid: user.uid,
        tokens: firestore.FieldValue.arrayUnion(token),
      },
      { merge: true }
    )
}

export async function getToken() {
  if (Platform.OS === 'web') {
    // Web-specific code
    if (!firebase.messaging.isSupported()) {
     // console.warn('Firebase Messaging is not supported in this browser.');
      return null;
    }
    try {
      const currentToken = await firebase.messaging().getToken({
        vapidKey: 'BKQkjckzN8pSOfsZ1QOzrde1jB-2nowtTPAs19qYqgYKR0T_4cD3hKlZXE9p5rETE1yVzBfxr5Qoj8aLS5qIj58',
      });
      //console.log('DEVICE TOKEN =', currentToken);
      return currentToken;
    } catch (error) {
      //console.error('Error getting token:', error);
      return null;
    }
  } else {
    // Mobile-specific code
    const token = await firebase.messaging().getToken();
    //console.log('DEVICE TOKEN =', token);
    return token;
  }
}

export async function requestUserPermission() {
  if (Platform.OS === 'web') {
    // Web-specific permission request
    if (!firebase.messaging.isSupported()) {
      //console.warn('Push notifications are not supported by your browser.');
      return false;
    }
    try {
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
       /// console.log('Push notifications permission granted.');
        return true;
      } else {
        Alert.alert('Permission denied', 'You will not receive push notifications.');
        return false;
      }
    } catch (error) {
      //console.error('Permission request error:', error);
      return false;
    }
  } else {
    // Mobile-specific permission request
    const authStatus = await firebase.messaging().requestPermission();
    const enabled =
      authStatus === firebase.messaging.AuthorizationStatus.AUTHORIZED ||
      authStatus === firebase.messaging.AuthorizationStatus.PROVISIONAL;
    if (enabled) {
      //console.log('Authorization status:', authStatus);
      return true;
    } else {
      //console.log('Push notifications permission denied.');
      return false;
    }
  }
}

export function useNotifications(user, navigate) {
  const onInAppMessageReceived = async (remoteMessage) => {
    if (Platform.OS !== 'web') {
      await notifee.displayNotification({
        data: remoteMessage.data,
        title: remoteMessage.notification.title,
        body: remoteMessage.notification.body,
      });
    } else {
      //console.log(remoteMessage, " HI NA ME O!!!!!, THE BUG GUY");
      // Check if Notification API is available
      if (typeof Notification !== 'undefined') {
        new Notification(remoteMessage.notification.title, {
          body: remoteMessage.notification.body,
          tag: remoteMessage.notification.tag || "",
          icon: '/logoN.png',
        });
      } else {
        //console.error('Notification API is not available in this environment.');
      }
    }
  };

  const onMessageReceived = useMemo(() => {
    return (remoteMessage) => {
      navigate(routeNames.NOTIFICATIONS_NAVIGATOR);
    };
  }, [navigate]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      // Web-specific message handling
      if (!firebase.messaging.isSupported()) {
       // console.warn('Firebase Messaging is not supported in this browser.');
        return;
      }
      const messaging = firebase.messaging();
      messaging.onMessage((payload) => {
        //console.log('Message received. ', payload);
        onInAppMessageReceived(payload);
      });
    } else {
      // Mobile-specific message handling
      const unsubscribe = firebase.messaging().onMessage(async (remoteMessage) => {
        onInAppMessageReceived(remoteMessage);
      });
      return unsubscribe;
    }
  }, [onInAppMessageReceived]);

  useEffect(() => {
    if (Platform.OS === 'web') {
      // Web-specific code (if applicable)
    } else {
      // Mobile-specific code
      firebase.messaging().onNotificationOpenedApp((remoteMessage) => {
        onMessageReceived(remoteMessage);
      });

      firebase
        .messaging()
        .getInitialNotification()
        .then((remoteMessage) => {
          if (remoteMessage) {
            onMessageReceived(remoteMessage);
          }
        });
    }
  }, [onMessageReceived]);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      // Mobile-specific foreground event handling
      return notifee.onForegroundEvent(({ type, detail }) => {
        if (type === EventType.PRESS) {
          onMessageReceived(detail.notification);
        }
      });
    }
  }, [onMessageReceived]);

  useEffect(() => {
    if (!user || !isMessagingSupported()) {
      //console.log("::::::: done :::::")
      return;
    }

    async function handleToken() {
      const permissionResult = await requestUserPermission();

      if (permissionResult) {
        const token = await getToken();
        if (token) {
          // Optionally save the token to your database
          saveTokenToDatabase(token)
        }
      }

      if (Platform.OS === 'web') {
        // Web-specific token refresh handling
        const messaging = firebase.messaging();
        messaging.onTokenRefresh((token) => {
          // Optionally save the token to your database
        });
      } else {
        // Mobile-specific token refresh handling
        return firebase.messaging().onTokenRefresh((token) => {
          // Optionally save the token to your database
        });
      }
    }

    handleToken();
  }, [user]);
}
