// serviceWorkerRegistration.js

// This code is used to register the Firebase Messaging service worker.
// By default, it registers 'firebase-messaging-sw.js' to enable push notifications.
// It ensures that the service worker is registered in both development and production environments,
// including localhost.

export function register(config) {
  console.log("::::REGISTERING SERVICE WORKER FOR FCM");

  // Check if the browser supports service workers
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const swUrl = `/firebase-messaging-sw.js`;
      console.log(swUrl, ' ::::: Service Worker URL');

      // Check if Firebase Messaging is supported in this browser
      if (!firebase.messaging.isSupported()) {
        console.warn('Firebase Messaging is not supported in this browser.');
        return;
      }

      // Register the service worker for Firebase Messaging
      navigator.serviceWorker
        .register(swUrl, { scope: '/' })
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);

          // Optional: Handle service worker updates
          if (registration.waiting) {
            handleUpdate(registration, config);
          }

          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker == null) {
              return;
            }

            installingWorker.onstatechange = () => {
              if (installingWorker.state === 'installed') {
                if (navigator.serviceWorker.controller) {
                  // New content is available; please refresh.
                  console.log('New content is available; please refresh.');

                  // Execute callback for new content
                  if (config && config.onUpdate) {
                    config.onUpdate(registration);
                  }
                } else {
                  // Content is cached for offline use.
                  console.log('Content is cached for offline use.');

                  // Execute callback for success
                  if (config && config.onSuccess) {
                    config.onSuccess(registration);
                  }
                }
              }
            };
          };
        })
        .catch((error) => {
          console.error('Error during service worker registration:', error);
        });
    });
  } else {
    console.warn('Service workers are not supported in this browser.');
  }
}

function handleUpdate(registration, config) {
  // Prompt user to refresh the page
  console.log('An updated service worker is waiting to activate.');

  // Optionally, notify the user or force the update
  // For example, you might display a modal prompting the user to refresh
  registration.waiting.postMessage({ type: 'SKIP_WAITING' });

  if (config && config.onUpdate) {
    config.onUpdate(registration);
  }
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
        console.log('Service worker unregistered.');
      })
      .catch((error) => {
        console.error('Error during service worker unregistration:', error);
      });
  }
}
